import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../Utils/ImiData";

const gameListApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getGameList: builder.query({
      query: () => ({
        url: `/lgo/catalog/623c3923b5ad280398e0de3b?page=0&size=10`,
        method: "GET",
        headers: {},
      }),
      transformResponse: (response) => response?.data.dataRows,
    }),
  }),
});

export const { useGetGameListQuery } = gameListApi;
export default gameListApi;
