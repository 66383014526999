import React, { useEffect, useRef, useState } from "react";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {
  useGetUserProfileMutation,
  useGetUserTokenFbMutation,
  useGetUserTokenGoogleMutation,
} from "../Services/LoginApi";
import { useDispatch, useSelector } from "react-redux";
import { homeActions } from "../Store/store";
import { fbAppID, gameUUIDs, googleWebClientID } from "../Utils/ImiData";
import packageData from "../../package.json";
import { useGetGameListQuery } from "../Services/gameListApi";

export default function Home() {
  //#region HOOKS
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.home.userData);
  const [apiStateIndex, setApiStateIndex] = useState(0);

  const tempUserToken = useRef(null);
  const gameListRef = useRef(null);
  const inGameLoginUrl = useRef(null);

  const dataKey = "nestamolt_awrudu_key";

  useEffect(() => {
    var el = document.getElementById("preloader");
    setTimeout(function () {
      el.style.opacity = "0";
      dis_none(el);
    }, 2000);
    function dis_none(element) {
      setTimeout(function () {
        element.style.display = "none";
      }, 500);
    }

    //localStorage.removeItem(dataKey);

    if (userData === null) {
      let data = localStorage.getItem(dataKey);

      if (data) {
        data = JSON.parse(data);
        dispatch(
          homeActions.setUserData({ token: data.token, image: data.image })
        );
      }
    }
  }, []);

  //#endregion

  //#region  PRELOADER

  const preLoader = (
    <div className="pre_loader_bg" id="preloader">
      <div
        className="preloader_anim"
        style={{
          backgroundImage: 'url("assets/images/load.gif")',
        }}
      ></div>
    </div>
  );

  const preLoader2 = (
    <div className="pre_loader_bg" id="preloader2">
      <div
        className="preloader_anim"
        style={{
          backgroundImage: 'url("assets/images/load.gif")',
        }}
      ></div>
    </div>
  );
  //#endregion

  //#region GAME LIST API
  const {
    isLoading: gameListAPILoading,
    isSuccess: gameListAPISuccess,
    data: gameListAPIData,
    error: gameListAPIError,
  } = useGetGameListQuery();

  if (gameListAPILoading) {
    //console.log("loading");
  } else if (gameListAPISuccess) {
    //console.log(gameListAPIData);
    gameListRef.current = gameListAPIData;
  } else {
    // console.log(gameListAPIError);
    window.location.reload();
  }
  //#endregion

  //#region  LOGIN API STATE
  //FB API
  const [
    fbLogin,
    {
      isLoading: fbAPILoading,
      isSuccess: fbAPISuccess,
      data: fbAPIData,
      error: fbAPIError,
    },
  ] = useGetUserTokenFbMutation();

  //GOOGLE API
  const [
    googleLogin,
    {
      isLoading: googleAPILoading,
      isSuccess: googleAPISuccess,
      data: googleAPIData,
      error: googleAPIError,
    },
  ] = useGetUserTokenGoogleMutation();

  //USER PROFILE API
  const [
    userProfile,
    {
      isLoading: userProfileAPILoading,
      isSuccess: userProfileAPISuccess,
      data: userProfileAPIData,
      error: userProfileAPIError,
    },
  ] = useGetUserProfileMutation();

  const responseFacebook = (response) => {
    //console.log(response);
    if (response.accessToken) {
      fbLogin({
        fbToken: response.accessToken,
      });
      setApiStateIndex(1);
    } else {
      window.$("#error_modal").modal("show");
    }
  };

  const responseGoogle = (response) => {
    //console.log(response);
    if (response.tokenId) {
      googleLogin({
        googleToken: response.tokenId,
      });
      setApiStateIndex(2);
    } else {
      window.$("#error_modal").modal("show");
    }
  };

  const signOutBtnClick = () => {
    inGameLoginUrl.current = null;
    localStorage.removeItem(dataKey);
    dispatch(homeActions.setUserData(null));
  };

  let loginStateContent;
  if (apiStateIndex === 1) {
    if (fbAPILoading) {
      loginStateContent = preLoader2;
    } else if (fbAPISuccess) {
      setApiStateIndex(3);
      userProfile({ token: fbAPIData.data.access_token });
      tempUserToken.current = fbAPIData.data.access_token;
    } else if (fbAPIError) {
      //console.log(fbLoginAPIError);
      setApiStateIndex(0);
      window.$("#error_modal").modal("show");
    }
  } else if (apiStateIndex === 2) {
    if (googleAPILoading) {
      loginStateContent = preLoader2;
    } else if (googleAPISuccess) {
      //console.log(googleAPIData);

      setApiStateIndex(3);
      userProfile({ token: googleAPIData.data.access_token });
      tempUserToken.current = googleAPIData.data.access_token;
    } else if (googleAPIError) {
      //console.log(googleAPIError);
      setApiStateIndex(0);
      window.$("#error_modal").modal("show");
    }
  } else if (apiStateIndex === 3) {
    if (userProfileAPILoading) {
      loginStateContent = preLoader2;
    } else if (userProfileAPISuccess) {
      dispatch(
        homeActions.setUserData({
          token: tempUserToken.current,
          image: userProfileAPIData.data.image_url,
        })
      );

      localStorage.setItem(
        dataKey,
        JSON.stringify({
          token: tempUserToken.current,
          image: userProfileAPIData.data.image_url,
        })
      );

      setApiStateIndex(0);

      if (inGameLoginUrl.current) {
        window.location.href = `${inGameLoginUrl.current}&user=${tempUserToken.current}`;
      } else {
        window.$("#succ_msg").modal("show");
      }
    } else if (userProfileAPIError) {
      //console.log(userProfileAPIError);
      setApiStateIndex(0);
      window.$("#error_modal").modal("show");

      if (inGameLoginUrl.current) {
        inGameLoginUrl.current = null;
      }
    }
  } else {
    loginStateContent = null;
  }
  //#endregion

  //#region  APPBAR

  const homeAddress = "https://nestomaltawurudu.arimac.digital";
  //const homeAddress = "http://localhost:3000/";

  const appBar = (
    <div className="scene_element scene_element--fadein">
      <div className="overlay" id="overlay">
        <nav className="overlay-menu">
          <ul>
            <li>
              <a href={homeAddress}>Home</a>
            </li>
            {!userData && (
              <li className="">
                <a>
                  <FacebookLogin
                    appId={fbAppID}
                    autoLoad={false}
                    isMobile={true}
                    disableMobileRedirect={true}
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <button
                        style={{
                          backgroundColor: "rgb(250,159,20)",
                          border: "none",
                        }}
                        onClick={renderProps.onClick}
                      >
                        <span>Sign In&nbsp;&nbsp;</span>
                        <i className="fab fa-facebook-f"></i>
                      </button>
                    )}
                  />
                </a>
              </li>
            )}
            {!userData && (
              <li className="">
                <a>
                  <GoogleLogin
                    clientId={googleWebClientID}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    autoLoad={false}
                    render={(renderProps) => (
                      <button
                        style={{
                          backgroundColor: "rgb(250,159,20)",
                          border: "none",
                        }}
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        <span>Sign In&nbsp;&nbsp;</span>
                        <i className="fab fa-google"></i>
                      </button>
                    )}
                    icon={false}
                  />
                </a>
              </li>
            )}
            {userData && (
              <li className="">
                <a>
                  <button
                    style={{
                      backgroundColor: "rgb(250,159,20)",
                      border: "none",
                    }}
                    onClick={signOutBtnClick}
                  >
                    Sign out
                  </button>
                </a>
              </li>
            )}
          </ul>
        </nav>
      </div>
      <div className="row relat">
        <div className="logo-wrap">
          <img alt="" src="assets/images/logo.png" height="100%" />
        </div>
        {!userData && (
          <h4 className="hidden-lg hidden-md hidden-sm mob-sign-in">Sign In</h4>
        )}
        <div className="col-sm-12">
          <nav className="navbar navbar-default ">
            <div className="navbar-header">
              <div
                className="button_container hidden-md hidden-lg hidden-sm"
                id="toggle"
              >
                <span className="top"></span>
                <span className="middle"></span>
                <span className="bottom"></span>
              </div>
            </div>
            <ul className="nav navbar-nav navbar-right hidden-xs">
              <li className=" active ">
                <a href={homeAddress}>Home</a>
              </li>
              {!userData && (
                <li className="">
                  <FacebookLogin
                    appId={fbAppID}
                    isMobile={true}
                    disableMobileRedirect={true}
                    autoLoad={false}
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <button
                        style={{
                          backgroundColor: "rgb(250,159,20)",
                          border: "none",
                        }}
                        onClick={renderProps.onClick}
                      >
                        <span>Sign In&nbsp;&nbsp;</span>
                        <i className="fab fa-facebook-f"></i>
                      </button>
                    )}
                  />
                </li>
              )}
              {!userData && (
                <li className="">
                  <GoogleLogin
                    clientId={googleWebClientID}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    autoLoad={false}
                    render={(renderProps) => (
                      <button
                        style={{
                          backgroundColor: "rgb(250,159,20)",
                          border: "none",
                        }}
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        <span>Sign In&nbsp;&nbsp;</span>
                        <i className="fab fa-google"></i>
                      </button>
                    )}
                    icon={false}
                  />
                </li>
              )}
              {userData && (
                <li className="">
                  <button
                    style={{
                      backgroundColor: "rgb(250,159,20)",
                      border: "none",
                    }}
                    onClick={signOutBtnClick}
                  >
                    Sign out
                  </button>
                </li>
              )}
            </ul>
            {userData && (
              <div
                style={{
                  backgroundImage: "url(" + userData.image + ")",
                }}
                className="pro-pic"
              ></div>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
  //#endregion

  //#region  GAME LIST

  const gameBtnOnClick = (uuid) => {
    let gameData = gameListRef.current.find((obj) => {
      return obj.additional_info.uuid === uuid;
    });

    let url = `${gameData.item_url}?platform=nestomalt`;

    if (userData) {
      window.location.href = `${url}&user=${userData.token}`;
    } else {
      inGameLoginUrl.current = url;
      window.$("#lmessage").modal("show");
    }
  };

  const gameList = (
    <div id="main" className="m-scene">
      <div className="scene_element scene_element--fadein">
        <div className="row set_win_h">
          <div className="col-sm-12 monster">
            <button onClick={() => gameBtnOnClick(gameUUIDs.Kotta_Pora)}>
              <div className="kotta-pora tap">
                <div className="popover-example hvr-wobble-vertical">
                  <div className="popover kotta-labal">
                    <div className="arrow"></div>
                    <div className="popover-content">Kotta Pora</div>
                  </div>
                </div>
              </div>
            </button>
            <button onClick={() => gameBtnOnClick(gameUUIDs.Lissana_Gaha)}>
              <div className="lissana-gaha tap">
                <div className="popover-example hvr-wobble-vertical">
                  <div className="popover lissana-labal">
                    <div className="arrow"></div>
                    <div className="popover-content">Lissana Gaha</div>
                  </div>
                </div>
              </div>
            </button>
            <button onClick={() => gameBtnOnClick(gameUUIDs.Kamba_Adeema)}>
              <div className="kamba-adima tap">
                <div className="popover-example hvr-wobble-vertical">
                  <div className="popover Kamba-labal">
                    <div className="arrow"></div>
                    <div className="popover-content">Kamba Adeema</div>
                  </div>
                </div>
              </div>
            </button>
            <button onClick={() => gameBtnOnClick(gameUUIDs.Gama_Haraha)}>
              <div className="gama-haraha tap">
                <div className="popover-example hvr-wobble-vertical">
                  <div className="popover gama-labal">
                    <div className="arrow"></div>
                    <div className="popover-content">Gama Haraha</div>
                  </div>
                </div>
              </div>
            </button>
            <button onClick={() => gameBtnOnClick(gameUUIDs.Kana_Mutti)}>
              <div className="kana-mutti tap">
                <div className="popover-example hvr-wobble-vertical">
                  <div className="popover kana-labal">
                    <div className="arrow"></div>
                    <div className="popover-content">Kana Mutti</div>
                  </div>
                </div>
              </div>
            </button>
            <button
              onClick={() =>
                gameBtnOnClick(
                  gameUUIDs.Handa_matha_vitamin_bola_thabagena_yama
                )
              }
            >
              <div className="aliyata-aha tap">
                <div className="popover-example hvr-wobble-vertical">
                  <div className="popover aliyata-labal">
                    <div className="arrow"></div>
                    <div className="popover-content">
                      Handa matha vitamin bola thabagena yama
                    </div>
                  </div>
                </div>
              </div>
            </button>
            <a href="http://onelink.to/7etp77">
              <div className="knoladge-hub tap">
                <div className="popover-example hvr-wobble-vertical">
                  <div className="popover knoladge-labal">
                    <div className="arrow"></div>
                    <div className="popover-content">Gift Now</div>
                  </div>
                </div>
              </div>
            </a>
            <a href="http://onelink.to/7etp77">
              <div className="litha-ani tap">
                <div className="popover-example hvr-wobble-vertical">
                  <div className="popover litha-labal">
                    <div className="arrow"></div>
                    <div className="popover-content">Nakath</div>
                  </div>
                </div>
              </div>
            </a>
            <a href="http://onelink.to/7etp77">
              <div className="sms-portal tap">
                <div className="popover-example hvr-wobble-vertical">
                  <div className="popover sms-labal">
                    <div className="arrow"></div>
                    <div className="popover-content">WhatsApp Stickers</div>
                  </div>
                </div>
              </div>
            </a>
            <a href="http://onelink.to/7etp77">
              <div className="wish-portal tap">
                <div className="popover-example hvr-wobble-vertical">
                  <div className="popover wish-labal">
                    <div className="arrow"></div>
                    <div className="popover-content">Wishes</div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
  //#endregion

  //#region  SMS SUCCESS MODAL

  const smsSuccessModal = (
    <div
      className="modal fade"
      id="success_modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="mySmallModalLabel"
    >
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content suc_modal_cont">
          <div className="modal-header">
            <button
              type="button"
              className="popup_cont_ico error_color"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span className="glyphicon glyphicon-remove"></span>
            </button>
            <h4 className="modal-title success_color" id="myModalLabel">
              Success
            </h4>
          </div>
          <div className="modal-body text-center">
            <p className="popup_cont">SMS Send Successfully Completed</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default sucModal_okBtn"
              data-dismiss="modal"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  //#endregion

  //#region  ERROR MODAL

  const errorModal = (
    <div
      className="modal fade"
      id="error_modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="mySmallModalLabel"
    >
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content suc_modal_cont">
          <div className="modal-header">
            {/* <button
              type="button"
              className="popup_cont_ico error_color"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span className="glyphicon glyphicon-remove"></span>
            </button> */}
            <h4 className="modal-title error_color" id="myModalLabel">
              Error
            </h4>
          </div>
          <div className="modal-body text-center">
            <p className="popup_cont">Something Went Wrong</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default sucModal_okBtn"
              data-dismiss="modal"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  //#endregion

  //#region  LOGIN SUCCESS MODAL

  const loginSuccessModel = (
    <div
      className="modal fade bs-example-modal-sm"
      tabIndex="-1"
      id="succ_msg"
      role="dialog"
      aria-labelledby="mySmallModalLabel"
    >
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content text-center">
          <h3 className="text-center">WELCOME!</h3>
          <img
            alt=""
            src="assets/images/nest_login.png"
            className="img-responsive"
          />
          <h4 className="text-center">Login Success</h4>
          <button
            data-dismiss="modal"
            className="btn btn-warninig btn-sign-out"
            style={{
              float: "none",
              textAlign: "center",
              outline: "none",
              margin: "10px 0 18px",
            }}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
  //#endregion

  //#region  FOOTER

  const footer = (
    <div className="row foot">
      <div className="col-sm-6">
        <h5 className="text-left" style={{ margin: "5px 0", fontSize: "12px" }}>
          {`© 2022 | ${packageData.version}v | NESTOMALT | All Rights Reserved.`}
        </h5>
      </div>
      <div className="col-sm-6">
        <h5 className="text-right">
          <a
            target="_blank"
            href="http://imigames.io"
            className="footer_power"
            rel="noreferrer"
          >
            <img
              alt=""
              style={{
                float: "right",
                marginTop: "-9px",
                height: "23px",
                width: "auto",
              }}
              src="assets/images/powered.png"
            />
          </a>
        </h5>
      </div>
    </div>
  );
  //#endregion

  //#region  SIGN IN REQUEST MSG
  const playAsGuestBtnClick = () => {
    window.$("#lmessage").modal("hide");
    window.location.href = inGameLoginUrl.current;
  };

  const signInRequestMsg = (
    <div
      className="modal fade"
      id="lmessage"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="mySmallModalLabel"
    >
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content suc_modal_cont">
          <div className="modal-header">
            <button
              type="button"
              className="popup_cont_ico error_color"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span className="glyphicon glyphicon-remove"></span>
            </button>
            <h4 className="modal-title success_color" id="myModalLabel">
              Message
            </h4>
          </div>
          <div className="modal-body text-center">
            <p className="popup_cont">
              Please log in via social media to register your score on the
              leaderboard
            </p>
            <div style={{ height: "30px" }}></div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FacebookLogin
                appId={fbAppID}
                isMobile={true}
                disableMobileRedirect={true}
                autoLoad={false}
                callback={responseFacebook}
                render={(renderProps) => (
                  <button
                    style={{
                      backgroundColor: "#ffe342",
                      border: "none",
                      padding: "18px 16px",
                      color: "#fff",
                      fontSize: "16px",
                    }}
                    onClick={() => {
                      renderProps.onClick();
                      window.$("#lmessage").modal("hide");
                    }}
                  >
                    <span style={{ color: "blue" }}>Sign In&nbsp;&nbsp;</span>
                    <i
                      style={{ color: "blue" }}
                      className="fab fa-facebook-f"
                    ></i>
                  </button>
                )}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <GoogleLogin
                clientId={googleWebClientID}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                autoLoad={false}
                render={(renderProps) => (
                  <button
                    style={{
                      backgroundColor: "#ffe342",
                      border: "none",
                      padding: "18px 16px",
                      color: "#fff",
                      fontSize: "16px",
                    }}
                    onClick={() => {
                      renderProps.onClick();
                      window.$("#lmessage").modal("hide");
                    }}
                    disabled={renderProps.disabled}
                  >
                    <span style={{ color: "blue" }}>Sign In&nbsp;&nbsp;</span>
                    <i style={{ color: "blue" }} className="fab fa-google"></i>
                  </button>
                )}
                icon={false}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                style={{
                  backgroundColor: "#ffe342",
                  border: "none",
                  padding: "18px 16px",
                  color: "#fff",
                  fontSize: "16px",
                }}
                onClick={playAsGuestBtnClick}
              >
                <span style={{ color: "blue" }}>play as Guest</span>
              </button>
            </div>
          </div>
          <div className="modal-footer">
            {/* <button
              type="button"
              className="btn btn-default sucModal_okBtn"
              data-dismiss="modal"
            >
              OK
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
  //#endregion

  //#region  AUDIO

  const audioContent = (
    <div id="backgroundaudio">
      <audio id="player" autoPlay loop preload="auto">
        <source src="assets/audio/sound2.mp3" type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      <a
        href="myClick"
        className="btn btn-success suc-btn-knw"
        data-toggle="tooltip"
        title="Preview"
        id="bgAudio_tog"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <i id="stateicon" className="sound">
          <span
            className="glyphicon glyphicon-volume-off pause-ico"
            aria-hidden="true"
          ></span>
          <span
            className="glyphicon glyphicon-volume-up play-ico"
            aria-hidden="true"
          ></span>
        </i>
      </a>
    </div>
  );
  //#endregion

  return (
    <div id="main" className="m-scene">
      {appBar}
      {gameList}
      {smsSuccessModal}
      {errorModal}
      {loginSuccessModel}
      {footer}
      {preLoader}
      {signInRequestMsg}
      {audioContent}
      {loginStateContent}
    </div>
  );
}
