//export const baseUrl = "https://api.staging.imigames.io/api/v1";
export const baseUrl = "https://api.imigames.io/api/v1";

export const googleWebClientID =
  "356024293196-ma8od8hv9nuhrqiu3n7ms8cb4is7f8ck.apps.googleusercontent.com";
export const fbAppID = "421341401639640";

//#region GAME URLS

export const gameUUIDs = {
  Kotta_Pora: "553a1153-4d25-4d93-8bb4-6a586779e02f",
  Lissana_Gaha: "cb929c76-dea9-4219-9189-9b531555dff6",
  Kamba_Adeema: "67d39336-6e3f-4f79-83f7-052e3ff2e8ed",
  Gama_Haraha: "6ce4b74c-282b-4480-a809-eed2c735c2c4",
  Kana_Mutti: "5d2e0ba9-56df-407d-be6b-0e42f1a91ae5",
  Handa_matha_vitamin_bola_thabagena_yama:
    "0d10be11-5311-450a-bbdf-b9c41473e023",
};

//#endregion
