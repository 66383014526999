import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../Utils/ImiData";

const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getUserProfile: builder.mutation({
      query: ({ token }) => ({
        url: `user/user`,
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
        },
      }),
    }),
    getUserTokenFb: builder.mutation({
      query: ({ fbToken }) => ({
        url: `user/signup/fb?token=${fbToken}`,
        method: "POST",
        headers: {
          "cache-control": "no-cache",
          "x-platform": "web",
        },
      }),
    }),
    getUserTokenGoogle: builder.mutation({
      query: ({ googleToken }) => ({
        url: `user/signup/google_sign_in?token=${googleToken}`,
        method: "POST",
        headers: {
          "cache-control": "no-cache",
          "x-platform": "web",
        },
      }),
    }),
  }),
});

export const {
  useGetUserProfileMutation,
  useGetUserTokenFbMutation,
  useGetUserTokenGoogleMutation,
} = loginApi;
export default loginApi;
