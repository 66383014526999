import { configureStore, createSlice } from "@reduxjs/toolkit";
import gameListApi from "../Services/gameListApi";
import loginApi from "../Services/LoginApi";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    count: 100,
    userData: null,
  },
  reducers: {
    increase: (state) => {
      state.count++;
    },
    decrease: (state) => {
      state.count--;
    },
    setUserData: (state, action) => {
      if (action.payload) {
        state.userData = {
          token: action.payload.token,
          image: action.payload.image,
        };
      } else {
        state.userData = null;
      }
    },
  },
});

const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [gameListApi.reducerPath]: gameListApi.reducer,
  },
  //CACHING WILL BE HANDLED BY THE MIDDLEWARE
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(loginApi.middleware)
      .concat(gameListApi.middleware),
});

export const homeActions = homeSlice.actions;

export default store;
